<template>
  <div class="contanier">
    <div class="main">
      <div class="leftBox">
        <div class="catalogue">
          <img src="../../assets/evaluation/img9.png" class="imgBox" />
          <div class="headline">
            <div class="headline-content" v-for="(item, index) in LevelsList" :key="index">
              <div
                class="headline-title"
                :class="[
                  {
                    activeWathet: item.accomplish,
                    activeOrage: levelsIndex == index,
                  },
                ]"
                @click="clickLevels(item,index)"
              >
                <span>{{ item.sortName }}</span>
                <img src="../../assets/images/finish.png" alt v-show="item.accomplish" />
              </div>
              <div class="headline-bg" v-if="index != LevelsList.length - 1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="content">
          <div class="topic">
            <div class="title">{{ levels }}答题表</div>
            <div class="details">
              <span>二级条款细分等级描述：</span>
              <span @click="dialogVisible = true">
                <span></span>
                <span>描述详情</span>
              </span>
            </div>
          </div>
          <div class="questionAllBox">
            <div class="questionBox">
              <div class="questionTable" v-for="(item,index) in answerList" :key="index">
                <div class="questionHead">{{ item.subSortName }}</div>
                <div class="questionBody" v-for="(v,i) in item.lstOptions" :key="i">
                  <el-radio-group v-model="v.answers" @change="clickText(v)">
                    <el-radio :label="v.options.substring(0, 1)">{{ v.options }}</el-radio>
                  </el-radio-group>
                </div>
                <div v-for="x in item.lstOptions" :key="x.id">
                  <div class="questionFooter" v-if="x.answers">
                    <div class="describe">二级条款细分等级描述：</div>
                    <div class="rate">
                      <div :class="{activeRate:x.secondAnswer == '优'}" @click="clickRate(x,'优')">
                        <span>优</span>
                      </div>
                      <div :class="{activeRate:x.secondAnswer == '良'}" @click="clickRate(x,'良')">
                        <span>良</span>
                      </div>
                      <div :class="{activeRate:x.secondAnswer == '中'}" @click="clickRate(x,'中')">
                        <span>中</span>
                      </div>
                      <div
                        :class="{activeRate:x.secondAnswer == '差'}"
                        v-show="x.order != 5"
                        @click="clickRate(x,'差')"
                      >
                        <span>差</span>
                      </div>
                      <div
                        :class="{activeRate:x.secondAnswer == '0分'}"
                        v-show="x.order == 1"
                        @click="clickRate(x,'0分')"
                      >
                        <span>0分</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <el-button type="primary" @click="handleSave">保存</el-button>
          <el-button type="warning" @click="submit">提交</el-button>
          <el-button @click="exit">退出</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="二级条款细分等级描述" :visible.sync="dialogVisible" width="600px">
      <div>请参照以下四个评价要素进行综合评估，判断您的公司该条描述最符合那个等级？</div>
      <div>策划（P）：策划输出的适宜性、系统性、有效性；</div>
      <div>实施（D）：实施的全面性、持续性、严格性；</div>
      <div>检查（C）：监测依据的充分性、对象的准确性贺结果的显现性；</div>
      <div>处置（A）：对监测结果进行分析、评价，并决定是否实施改进;</div>
    </el-dialog>
  </div>
</template>

<script>
import { get, put } from "../../api/http";
export default {
  data() {
    return {
      dialogVisible: false,
      isFlag: true,
      questionList: [], // 题目列表
      LevelsList: [], // 左侧标题
      levels: "", // 默认第一个标题
      typeList: [], // 二级标题
      answerList: [], // 题目
      levelsIndex: 0,
      sonData: {},
      accomplishList: [],
      flag: false,
      answerAllList: [],
    };
  },
  created() {
    this.getList(JSON.parse(this.$route.query.params).id);
  },
  methods: {
    // 获取题目
    getList(query) {
      let url = this.isFlag
        ? "/api/EvaluateMainSub/GetByMainId?mainId=" + query
        : "/api/EvaluateMainSub/GetByMainIdAndName";
      get(this.isFlag ? url : url, this.isFlag ? "" : query).then((res) => {
        this.questionList = res.data;
        this.questionList.forEach((item) => {
          this.LevelsList.push({ sortName: item.sortName, accomplish: false });
          this.LevelsList = this.LevelsList.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.sortName === item.sortName)
          );
          this.levels = this.questionList[0].sortName;
        });
        // 二级标题
        this.typeList = [];
        this.questionList.map((item) => {
          if (item.sortName == this.levels) {
            this.typeList.push({ sort: item.subSortName });
            this.typeList = Array.from(
              new Set(this.typeList.map(JSON.stringify))
            ).map(JSON.parse);
          }
        });

        // 所有题目
        this.answerAllList = [];
        this.LevelsList.map((item) => {
          this.questionList.map((v) => {
            // 本页的题目
            if (item.sortName == v.sortName) {
              this.answerAllList.push({
                sortName: item.sortName,
                subSortName: v.subSortName,
                lstOptions: [],
              });
              this.answerAllList = Array.from(
                new Set(this.answerAllList.map(JSON.stringify))
              ).map(JSON.parse);
            }
          });
        });

        this.answerAllList.map((x) => {
          this.questionList.map((v) => {
            if (x.subSortName == v.subSortName) {
              x.lstOptions.push(v);
            }
          });
        });

        // 整理题目
        this.answerList = [];
        this.typeList.map((item) => {
          this.questionList.map((v) => {
            // 本页的题目
            if (item.sort == v.subSortName) {
              this.answerList.push({ subSortName: item.sort, lstOptions: [] });
              this.answerList = Array.from(
                new Set(this.answerList.map(JSON.stringify))
              ).map(JSON.parse);
            }
          });
        });
        this.answerList.map((x) => {
          this.questionList.map((v) => {
            if (x.subSortName == v.subSortName) {
              x.lstOptions.push(v);
            }
          });
        });

        // 问题添加字母选项
        this.answerList.forEach((item) => {
          let letterCode = "A".charCodeAt(0);
          item.lstOptions.forEach((option) => {
            let letter = String.fromCharCode(letterCode++);
            if (!option.options.includes(letter)) {
              option.options = `${letter}. ${option.options}`;
            }
            if (option.answers && option.secondAnswer !== "0分") {
              option.secondAnswer = option.secondAnswer.slice(0, 1);
            }
          });
        });

        // 判断是否完成题目
        this.accomplishList = [];
        this.LevelsList.forEach((i) => {
          this.answerAllList.forEach((v) => {
            if (i.sortName == v.sortName) {
              i.accomplish = v.lstOptions.some((v) => v.answers != "");
            }
          });
        });
      });
    },

    // 左侧一级标题
    clickLevels(levels, index) {
      this.levelsIndex = index;
      let queryParams = {
        mainId: JSON.parse(this.$route.query.params).id,
        typeName: levels.sortName,
      };
      this.isFlag = false;
      this.questionList = [];
      this.typeList = [];
      this.answerList = [];
      this.getList(queryParams);
    },
    // 选中题目
    clickText(son) {
      this.sonData = son;
      if (son.secondAnswer != "0分") {
        son.secondAnswer = son.secondAnswer.slice(0, 1);
      }
      this.answerList.forEach((item) => {
        if (item.subSortName == son.subSortName) {
          item.lstOptions.forEach((v) => {
            if (v.id == this.sonData.id) {
              this.sonData.answers = this.sonData.options.substring(0, 1);
            } else {
              v.answers = "";
              v.secondAnswer = "0分";
            }
          });
        }
      });
    },
    // 打分
    clickRate(row, rate) {
      row.secondAnswer = rate;
    },
    // 保存
    handleSave() {
      this.accomplishList = [];
      this.answerList.forEach((item) => {
        this.accomplishList.push(item.lstOptions.some((v) => v.answers != ""));
      });
      this.flag = this.accomplishList.every((item) => item);
      if (this.flag) {
        this.LevelsList.forEach((i) => {
          if (i.sortName == this.levels) {
            i.accomplish = true;
          }
        });
        let queryParams = [];
        this.answerList.forEach((item) => {
          item.lstOptions.forEach((v) => {
            v.anRemark = v.secondAnswer;
            queryParams.push(v);
          });
        });
        put("/api/EvaluateMainSub/PutMore", queryParams).then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功");
          }
        });
      } else {
        this.$message.warning("请完成" + this.levels + "的所有题目");
      }
    },
    // 提交
    submit() {
      let flag = this.LevelsList.every((item) => item.accomplish);
      let form = JSON.parse(this.$route.query.params);
      if (flag) {
        form.evaluateTag = 1;
        put("/api/EvaluateMain/" + form.id, form).then((res) => {
          if (res.code == 200) {
            this.$message.success("提交成功");
            this.$router.push("./HistoryPage");
          }
        });
      } else {
        this.$message.warning("请完成所有题目");
      }
    },
    // 退出
    exit() {
      let flag = this.LevelsList.every((item) => item.accomplish);
      let text = flag ? "是否退出答题" : "还有未完成的题目，是否退出？";
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("./HistoryPage");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: PingFang SC;
  src: url("../../assets/font/PingFang-SC.ttf");
}
.contanier {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.18rem 1.18rem 0 1.15rem;
  box-sizing: border-box;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .leftBox {
      width: 2.37rem;
      border-radius: 0.1rem;
      margin-right: 0.1rem;
      .catalogue {
        padding-top: 0.1rem;
        background: #ffffff;
        padding-bottom: 0.8rem;
        .imgBox {
          width: 2.37rem;
          height: 1.53rem;
        }
        .headline {
          position: relative;
          width: 1.63rem;
          margin: 0.15rem auto;
          padding-bottom: 0.23rem;
          overflow: hidden;
          z-index: 2;
          .headline-content {
            .activeOrage {
              background-color: #ff9307 !important;
            }
            .activeWathet {
              background-color: #5a8bff !important;
            }
            .headline-title {
              position: relative;
              width: 1.81rem;
              height: 0.38rem;
              background: #bfbfbf;
              border: 1px solid #676767;
              margin-left: -0.09rem;
              margin-top: 0.22rem;
              transform: rotate(-15deg);
              z-index: 2;
              text-align: center;
              line-height: 0.38rem;
              span {
                font-family: PingFang SC;
                font-weight: bold;
                font-size: 0.18rem;
                color: #ffffff;
                margin-right: 0.16rem;
              }
              img {
                width: 0.14rem;
                height: 0.14rem;
              }
            }
            .headline-bg {
              position: absolute;
              width: 1.81rem;
              height: 0.4rem;
              background-color: rgba(192, 192, 192, 0.404);
              margin-left: -0.09rem;
              margin-top: -0.09rem;
              transform: rotate(-174deg);
              z-index: 1;
            }
          }
        }
      }
    }
    .rightBox {
      flex: 1;
      background: #ffffff;
      .content {
        height: calc(100% - 1.09rem);
        padding: 0.36rem 0.6rem 0 0.42rem;
        .topic {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.14rem;
          .title {
            height: 0.3rem;
            line-height: 0.3rem;
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 0.3rem;
            color: #000000;
          }
          .details {
            & > span:nth-child(1) {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 0.14rem;
              color: #000000;
              margin-right: 0.15rem;
            }
            & > span:nth-child(2) {
              display: inline-block;
              width: 0.92rem;
              height: 0.32rem;
              background: #e5e5e5;
              border-radius: 0.02rem;
              text-align: center;
              line-height: 0.32rem;
              & > span:nth-child(1) {
                display: inline-block;
                width: 0.13rem;
                height: 0.13rem;
                background: #ff9307;
                border-radius: 50%;
                border: 1px solid #cacfd6;
                margin-right: 0.06rem;
                vertical-align: middle;
              }
              & > span:nth-child(2) {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 0.14rem;
                color: #333333;
                vertical-align: middle;
              }
            }
          }
        }
        .questionAllBox {
          height: calc(100% - 0.6rem);
          .questionBox {
            height: 100%;
            overflow-y: auto;
            .questionTable {
              margin-bottom: 0.14rem;
              border: 1px solid #d0d0d0;
              .questionHead {
                background: #e5e5e5;
                border-bottom: 1px solid #d0d0d0;
                font-family: PingFang SC;
                font-weight: 700;
                font-size: 0.16rem;
                color: #000000;
                padding: 0.21rem 0 0.21rem 0.3rem;
                line-height: 0.16rem;
              }
              .questionBody {
                padding: 0.12rem 0 0.12rem 0.3rem;
                .el-radio {
                  width: 100%;
                  white-space: pre-wrap;
                  margin-right: 0;
                }
              }
              .questionFooter {
                border-top: 1px solid #d0d0d0;
                padding: 0.13rem 0 0.11rem 0.3rem;
                display: flex;
                align-items: center;
                font-family: PingFang SC;
                font-weight: 500;
                font-size: 0.14rem;
                color: #666666;
                .describe {
                  margin-right: 0.26rem;
                }
                .rate {
                  line-height: 0.14rem;
                  display: flex;
                  div {
                    padding: 0.09rem 0.2rem;
                    background: #e8ebf0;
                    border-radius: 0.07rem;
                    border: 1px solid #e9eaed;
                    margin-right: 0.1rem;
                  }
                  .activeRate {
                    background: #5a8bff;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
      .footer {
        height: 0.72rem;
        border-top: 1px solid #d0d0d0;
        text-align: center;
        line-height: 0.72rem;
      }
    }
  }
}
</style>